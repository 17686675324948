import React from "react";
import CofContentCard from "./CofContentCard";

const CofListing = ({ fields }) => {
  return (
    <div className={`generic-listing ${fields["css classes"]?.value}`}>
      <div className="content">
        {fields.items.map((item, index) => {
          return <CofContentCard key={index} fields={item.fields} />;
        })}
      </div>
    </div>
  );
};

export default CofListing;
