import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";

const CofHorizontalCard = (fields) => {
  const generateRenditionUrl = (renditionWidth) => {
    const url = new URL(fields.image.value.src);
    url.searchParams.append("t", `w${renditionWidth}`);
    return url.toString();
  };

  return (
    <div className={"cof-horizontal-card"}>
      <div className="img-container">
        {fields.decorationImage?.value?.src && (
          <div className="decoration">
            <Image field={fields.decorationImage} />
          </div>
        )}

        <img
          alt={fields.image.value.alt}
          height={fields.image.value.height}
          loading="lazy"
          sizes={"(max-width: 767px) 480px, (max-width: 1023px) 800px, 1200px"}
          srcSet={`${generateRenditionUrl(480)} 480w, ${generateRenditionUrl(
            800,
          )} 800w, ${generateRenditionUrl(1200)} 1200w`}
          src={generateRenditionUrl(1200)}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundImage: `url("${generateRenditionUrl(20)}")`,
          }}
          width={fields.image.value.width}
        />
      </div>
      <div className="text-container">
        <RichText field={fields.title} />

        <RichText className="brief" field={fields.text} />
      </div>
    </div>
  );
};

export default CofHorizontalCard;
